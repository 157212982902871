import moment from "moment";
import { servicesNames } from "../contexts/serviceName";

export const createChartDataTotals = (data) => {
  const { data: graph_period } = data;
  const callsData = {
    total: 0,
    dataChart: [],
  };
  const period = {};

  let nameDay = "";
  const services = [
    servicesNames.DECISION_MAKER,
    servicesNames.DECISION_MAKER_INVOKE,
    servicesNames.USER_SCORE,
  ];

  try {
    graph_period.forEach((el) => {
      const day = moment(el.date);
      nameDay = day.format("DD/MM");
      if (nameDay in period) {
        let service = services.find((name) => name.backName === el.service);
        if (typeof service === "undefined") return;
        period[nameDay].total += el.count;
        period[nameDay][service.frontName] = el.count;
      } else {
        let service = services.find((name) => name.backName === el.service);
        if (typeof service === "undefined") return;
        period[nameDay] = {
          nameX: nameDay,
          total: el.count,
          [service.frontName]: el.count,
        };
      }
      callsData.total += el.count;
    });

    const sorted_keys = Object.keys(period).sort((a, b) => {
      const [day1, month1] = a.split("/").map(Number);
      const [day2, month2] = b.split("/").map(Number);

      if (month1 === 12 && month2 === 1) return -1;
      if (month1 === 1 && month2 === 12) return 1;

      return month1 - month2 || day1 - day2;
    });

    callsData.dataChart = sorted_keys.map((key) => period[key]);
  } catch (e) {
    console.log(e);
  }

  return callsData;
};
