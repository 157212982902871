import { useEffect, useState } from "react";
import LinkConfigBtnContainer from "./LinkConfigBtnContainer";
import LinkConfigHeader from "./LinkConfigHeader";
import LinkPreview from "./LinkPreview";
import InputRadio from "./InputRadio";
import TextInput from "./TextInput";
import {
  validateExternalID,
  validateURL,
  validateWebhookURL,
} from "../helpers/formValidations";
import { T, useTranslate } from "@tolgee/react";

const RadioInput = ({
  checked,
  handleChecked,
  handleClick,
  title,
  text,
  value,
  styles,
}) => {
  return (
    <div style={styles} className="configLink__inputRadio">
      <div className="configLink__inputRadio__input">
        <InputRadio
          color="blue"
          name="link-config"
          checked={checked}
          handleCheck={handleChecked}
          handleClick={handleClick}
          value={value}
        />
        <span className="text-1">{title}</span>
      </div>
      <p className="text-2 color--shuttle-gray">{text}</p>
    </div>
  );
};

const ConfigLink = ({ closeTab, linkData, setLinkData, initialForm }) => {
  const [form, setForm] = useState(initialForm);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslate();

  useEffect(() => {
    const {
      single,
      multiple,
      external_id,
      redirect_url,
      webhook_url,
      identify_user,
    } = form;

    const {
      single: initialSingle,
      multiple: initialMultiple,
      external_id: initialExternal_id,
      redirect_url: initialRedirect_url,
      webhook_url: initialWebhook_url,
      identify_user: initialIdentify_user,
    } = initialForm;

    if (
      single !== initialSingle ||
      multiple !== initialMultiple ||
      external_id !== initialExternal_id ||
      redirect_url !== initialRedirect_url ||
      webhook_url !== initialWebhook_url ||
      identify_user !== initialIdentify_user
    ) {
      if (form?.external_id && !validateExternalID(form?.external_id)) {
        setDisabled(() => true);
        return;
      }

      if (form?.redirect_url && !validateURL(form?.redirect_url)) {
        setDisabled(() => true);
        return;
      }

      if (form?.webhook_url && !validateWebhookURL(form?.webhook_url)) {
        setDisabled(() => true);
        return;
      }

      setDisabled(() => false);
    } else {
      setDisabled(() => true);
    }
  }, [form]);

  const saveConfig = () => {
    setLinkData((prev) => ({
      ...prev,
      config: {
        type:
          form?.single !== prev.config.type ? form.single : prev.config.type,
        external_id:
          form?.external_id !== prev.config.external_id
            ? form.external_id
            : prev.config.external_id,
        redirect_url: form?.redirect_url || null,
        webhook_url: form?.webhook_url || null,
        identify_user: form?.identify_user,
      },
    }));

    closeTab();
  };

  const handleChecked = ({ target: { value, checked } }) =>
    setForm((prev) => {
      if (value === "single")
        return {
          ...prev,
          single: checked,
          multiple: !checked,
          identify_user: false,
        };

      if (value === "multiple")
        return {
          ...prev,
          multiple: checked,
          single: !checked,
          external_id: undefined,
        };

      if (value === "identify_user") {
        const single = prev.single;

        if (single) {
          return {
            ...prev,
            identify_user: false,
          };
        }

        return {
          ...prev,
          identify_user: !prev.identify_user,
        };
      }
    });

  const handleClick = ({ target: { value } }) =>
    setForm((prev) => ({
      ...prev,
      [value]: prev.multiple ? !prev.identify_user : false,
    }));

  return (
    <section className="linkPage__configuration">
      <div className="linkPage__configuration__menu">
        <div className="configLink">
          <LinkConfigHeader
            title={t("link_config_title")}
            closeTab={closeTab}
          />
          <div className="configLink__data-container">
            <h4 className="text-1--bold">
              <T keyName="config_link_form_type_title" />
            </h4>
            <p className="text-2 color--shuttle-gray">
              <T keyName="config_link_form_type_text" />
            </p>

            <div className="configLink__form">
              <RadioInput
                checked={form.single}
                handleChecked={handleChecked}
                title={t("radio_input_unique_magic_link_titl")}
                text={t("radio_input_unique_magic_link_text")}
                value="single"
              />

              <TextInput
                form={form}
                inputName="external_id"
                description={t("input_user_id_magic_link_title")}
                inputLabel="user-id"
                validate={validateExternalID}
                setValue={setForm}
                disabled={form.multiple}
              />

              <RadioInput
                checked={form.multiple}
                handleChecked={handleChecked}
                title={t("radio_input_multiple_magic_link_title")}
                text={t("radio_input_multiple_magic_link_text")}
                value="multiple"
              />

              <RadioInput
                checked={form.identify_user}
                handleChecked={handleChecked}
                handleClick={handleClick}
                title={t("radio_input_identifier_magic_link_title")}
                text={t("radio_input_identifier_magic_link_text")}
                value="identify_user"
                styles={{ paddingLeft: "28px" }}
              />

              <div
                style={{ marginTop: "36px" }}
                className="configLink__input-group"
              >
                <span className="text-1">
                  <T keyName="magic_link_redirect_text" />
                </span>
                <p className="text-2 color--shuttle-gray">
                  <T keyName="magic_link_redirect_message" />
                </p>
                <TextInput
                  form={form}
                  inputName="redirect_url"
                  inputLabel="https://www.sitioweb.com"
                  validate={validateURL}
                  setValue={setForm}
                />
              </div>

              <div className="configLink__input-group">
                <span className="text-1">
                  <T keyName="magic_link_webhook_text" />
                </span>
                <p className="text-2 color--shuttle-gray">
                  <T keyName="magic_link_webhook_message" />
                </p>
                <TextInput
                  form={form}
                  inputName="webhook_url"
                  inputLabel="https://www.miwebhook.com"
                  validate={validateWebhookURL}
                  setValue={setForm}
                />
              </div>
            </div>
          </div>
        </div>

        <LinkConfigBtnContainer
          cancelBtnConfig={{ text: t("cancel_button"), handleClick: closeTab }}
          saveBtnConfig={{
            text: t("save_changes_button"),
            handleClick: saveConfig,
          }}
          enableSave={!disabled}
        />
      </div>
      <LinkPreview linkData={linkData} />
    </section>
  );
};
export default ConfigLink;
