export const generateReport = ({
  completeData,
  data,
  network_reports,
  types,
}) => {
  const gender = data?.face_recognition?.face_attributes?.gender;

  return {
    ...reportData(completeData),
    network_reports,
    flaggedFor: flaggedFor({ completeData, types }),
    warnings: generalWarnings(completeData),
    ...geospatial({ data, completeData }),
    ...documentIne({ data, completeData }),
    ...documentPassport({ data, completeData }),
    ...selfie({ data, completeData, gender }),
    ...rfc({ data, completeData }),
    ...curp({ data, completeData }),
    ...listedWeeks({ data, completeData }),
    ...email({ data, completeData }),
    ...phone({ data, completeData }),
    ...ip({ data, completeData }),
    ...studies(data),
    ...pep({ data, completeData }),
    ...sanctions({ data, completeData }),
  };
};

const geospatial = ({ data, completeData }) => ({
  address_validation: data?.enrichment?.geospatial?.address_validation?.error,
  real_address_geospatial:
    data?.address_enrichment?.geospatial?.address_validation?.address ||
    data?.enrichment?.geospatial?.address_validation?.address,
  inside_of_prison:
    data?.enrichment?.geospatial?.high_risk_locations?.inside_of_prison,
  near_to_prison:
    data?.address_enrichment?.geospatial?.high_risk_locations
      ?.inside_of_prison !== undefined
      ? data?.address_enrichment?.geospatial?.high_risk_locations
          ?.near_to_prison
      : data?.enrichment?.geospatial?.high_risk_locations?.near_to_prison,
  outside_of_territory:
    data?.address_enrichment?.geospatial?.high_risk_locations
      ?.inside_of_prison !== undefined
      ? data?.address_enrichment?.geospatial?.high_risk_locations
          ?.outside_of_territory
      : data?.enrichment?.geospatial?.high_risk_locations?.outside_of_territory,
  geospatial_warnings: completeData?.warnings?.geospatial
    ? [...completeData.warnings.geospatial]
    : [],
});

const documentIne = ({ data, completeData }) => ({
  document_type: data?.document_ocr?.document_ocr?.doc_type,
  document_name:
    data?.document_ocr?.document_ocr?.information?.complete_name?.text,
  document_valid_thru:
    data?.document_ocr?.document_ocr?.information?.valid_thru?.text,
  document_image_quality: data?.document_ocr?.image_quality,
  document_DoB:
    data?.document_ocr?.data_enrichment?.curp?.date_of_birth ||
    data?.enrichment?.curp?.date_of_birth,
  document_gender:
    data?.document_ocr?.data_enrichment?.curp?.gender ||
    data?.enrichment?.curp?.gender,
  document_nationality:
    data?.document_ocr?.data_enrichment?.curp?.is_mexican ||
    data?.enrichment?.curp?.is_mexican,
  document_SoB:
    data?.document_ocr?.data_enrichment?.curp?.state_of_birth ||
    data?.enrichment?.curp?.state_of_birth,
  curp_valid_ine: data?.document_ocr?.data_enrichment?.curp?.government_valid,
  valid_rfc: data?.document_ocr?.data_enrichment?.rfc?.validation?.valid,
  real_address_ine:
    data?.document_ocr?.data_enrichment?.geospatial?.address_validation
      ?.address,
  document_image: completeData?.images?.document_image,
  document_warnings: completeData?.warnings?.document
    ? [...completeData.warnings.document]
    : [],
});

const documentPassport = ({ data, completeData }) => ({
  document_expiration_date:
    data?.document_ocr?.document_ocr?.information?.expiration_date?.text,
  document_electoral_key:
    data?.document_ocr?.document_ocr?.information?.electoral_key?.valid,
  curp_valid_passport:
    data?.document_ocr?.document_ocr?.information?.curp?.valid,
});

const isThereSelfieData = (selfieData) => {
  let thereIs = false;
  for (const key in selfieData) {
    const element = selfieData[key];
    if (element) {
      thereIs = true;
      break;
    }
  }
  return thereIs;
};

const selfie = ({ data, completeData, gender }) => ({
  selfie_data: isThereSelfieData(data?.face_recognition),
  selfie_last_seen:
    data?.face_recognition?.last_seen ||
    data?.document_ocr?.face_recognition?.last_seen,
  selfie_first_seen:
    data?.face_recognition?.first_seen ||
    data?.document_ocr?.face_recognition?.first_seen,
  times_seen_last_month:
    data?.face_recognition?.times_seen_last_month ||
    data?.document_ocr?.face_recognition?.times_seen_last_month,
  selfie_gender: !gender
    ? undefined
    : gender === "male"
    ? "masculino"
    : "femenino",
  selfie_min_age: data?.face_recognition?.face_attributes?.minimum_age,
  selfie_max_age: data?.face_recognition?.face_attributes?.maximum_age,
  selfie_warnings: completeData?.warnings?.selfie
    ? [...completeData.warnings.selfie]
    : [],
  selfie: completeData?.images?.selfie,
});

const rfc = ({ data, completeData }) => ({
  blacklisted_rfc:
    data?.document_ocr?.data_enrichment?.rfc?.blacklist?.taxpayer_status ||
    data?.enrichment?.rfc?.blacklist?.taxpayer_status,
  rfc_number:
    data?.document_ocr?.data_enrichment?.rfc?.validation?.rfc ||
    data?.enrichment?.rfc?.validation?.rfc,
  rfc_warnings: completeData?.warnings?.rfc
    ? [...completeData.warnings.rfc]
    : [],
});

const curp = ({ data, completeData }) => ({
  curp_number:
    data?.document_ocr?.data_enrichment?.curp?.curp ||
    data?.enrichment?.curp?.curp,
  curp_name:
    data?.document_ocr?.data_enrichment?.curp?.government_name ||
    data?.enrichment?.curp?.government_name,
  curp_valid:
    data?.enrichment?.curp?.government_valid ||
    data?.document_ocr?.data_enrichment?.curp?.government_valid,
  curp_age:
    data?.document_ocr?.data_enrichment?.curp?.age ||
    data?.enrichment?.curp?.age,
  curp_warnings: completeData?.warnings?.curp
    ? [...completeData.warnings.curp]
    : [],
});

const listedWeeks = ({ data, completeData }) => ({
  listed_weeks:
    data?.listed_weeks?.employment_summary?.listed_weeks ||
    data?.document_ocr?.listed_weeks?.employment_summary?.listed_weeks,
  listed_weeks_jobs:
    data?.listed_weeks?.employment_summary?.total_jobs ||
    data?.listed_weeks?.document_ocr?.employment_summary?.total_jobs,
  listed_weeks_gob:
    data?.enrichment?.background_record?.government_positions
      ?.registered_positions,
  listed_weeks_avg_days:
    data?.listed_weeks?.employment_summary?.average_working_days ||
    data?.document_ocr?.listed_weeks?.employment_summary?.average_working_days,
  listed_weeks_longest_period:
    data?.listed_weeks?.employment_summary?.longest_job_period ||
    data?.document_ocr?.listed_weeks?.employment_summary?.longest_job_period,
  listed_weeks_shortest_period:
    data?.listed_weeks?.employment_summary?.shortest_job_period ||
    data?.document_ocr?.listed_weeks?.employment_summary?.shortest_job_period,
  listed_weeks_max_salary:
    data?.listed_weeks?.employment_summary?.max_base_salary ||
    data?.document_ocr?.listed_weeks?.employment_summary?.max_base_salary,
  listed_weeks_min_salary:
    data?.listed_weeks?.employment_summary?.min_base_salary ||
    data?.document_ocr?.listed_weeks?.employment_summary?.min_base_salary,
  listed_weeks_salary_summary:
    data?.document_ocr?.listed_weeks?.salary_summary ||
    data?.listed_weeks?.salary_summary,
  listed_weeks_salary_summary_gob:
    data?.enrichment?.background_record?.government_positions?.details,
});

const email = ({ data, completeData }) => ({
  valid_email: data?.enrichment?.external_fraud_service?.email?.valid,
  email_disposable: data?.enrichment?.external_fraud_service?.email?.disposable,
  email_valid_dns: data?.enrichment?.external_fraud_service?.email?.dns_valid,
  email_honeypot: data?.enrichment?.external_fraud_service?.email?.honeypot,
  suspect_email: data?.enrichment?.external_fraud_service?.email?.fraud_score,
  email: data?.enrichment?.external_fraud_service?.email?.sanitized_email,
  email_names:
    data?.enrichment?.external_fraud_service?.email?.associated_names?.names,
  email_phone:
    data?.enrichment?.external_fraud_service?.email?.associated_phone_numbers
      ?.phone_numbers,
  email_social_media_accounts: data?.enrichment?.social_media?.accounts,
  email_social_media_accounts_details:
    data?.enrichment?.social_media?.accounts_details,
  email_warnings: completeData?.warnings?.email
    ? [...completeData.warnings.email]
    : [],
});

const phone = ({ data, completeData }) => ({
  valid_phone: data?.enrichment?.external_fraud_service?.phone_number?.valid,
  valid_whatsapp_phone: data?.enrichment?.whatsapp?.is_valid,
  risky_phone: data?.enrichment?.external_fraud_service?.phone_number?.risky,
  phone_on_whatsapp: data?.enrichment?.whatsapp?.on_whatsapp,
  phone_city:
    data?.enrichment?.external_fraud_service?.phone_number?.city ||
    data?.enrichment?.whatsapp?.number?.region,
  phone_country:
    data?.enrichment?.external_fraud_service?.phone_number?.country ||
    data?.enrichment?.whatsapp?.number?.iso_country_code,
  phone_carrier:
    data?.enrichment?.external_fraud_service?.phone_number?.carrier ||
    data?.enrichment?.whatsapp?.number?.carrier,
  phone_line_type:
    data?.enrichment?.external_fraud_service?.phone_number?.line_type,
  phone_number:
    data?.enrichment?.external_fraud_service?.phone_number?.formatted ||
    data?.enrichment?.whatsapp?.number?.e164_format,
  phone_associated_emails:
    data?.enrichment?.external_fraud_service?.phone_number
      ?.associated_email_addresses?.emails,
  phone_warnings: completeData?.warnings?.phone_number
    ? [...completeData.warnings.phone_number]
    : [],
});

const ip = ({ data, completeData }) => ({
  ip_blacklisted: data?.enrichment?.ip?.blacklisted?.ip_blacklisted,
  ip_country_code: data?.enrichment?.external_fraud_service?.ip?.country_code,
  ip_mobile: data?.enrichment?.external_fraud_service?.ip?.mobile,
  ip_proxy: data?.enrichment?.external_fraud_service?.ip?.proxy,
  ip_tor: data?.enrichment?.external_fraud_service?.ip?.active_tor,
  ip_lat: data?.enrichment?.external_fraud_service?.ip?.latitude,
  ip_lng: data?.enrichment?.external_fraud_service?.ip?.longitude,
  ip_warnings: completeData?.warnings?.ip ? [...completeData.warnings.ip] : [],
});

const studies = (data) => ({
  studies: data?.enrichment?.background_record?.studies?.registered_studies,
  studies_details: data?.enrichment?.background_record?.studies?.details,
});

const pep = ({ data, completeData }) => ({
  pep:
    data?.enrichment?.background_record?.expanded_report?.records?.pep ||
    data?.enrichment?.background_record?.government_positions?.details,
  pep_warnings: completeData?.warnings?.pep
    ? [...completeData.warnings.pep]
    : [],
});

const sanctions = ({ data, completeData }) => ({
  sanctions:
    data?.enrichment?.background_record?.expanded_report?.records?.sanctions,
  sanctions_warnings: completeData?.warnings?.sanctions
    ? [...completeData.warnings.sanctions]
    : [],
});

const flaggedFor = ({ completeData, types }) =>
  completeData?.report
    ? {
        ...completeData?.report,
        wasFlagged: true,
        types,
        request_id: completeData?.id,
        identity_id: completeData?.request_id,
      }
    : {
        wasFlagged: false,
        types,
        description: "",
        request_id: completeData?.id,
        identity_id: completeData?.request_id,
      };

const generalWarnings = (completeData) =>
  completeData?.warnings?.others ? [...completeData.warnings.others] : [];

const reportData = (completeData, data) => ({
  json: completeData,
  id: completeData?.id,
  external_id: completeData?.external_id,
  request_id: completeData?.response?.request_id,
  service_name: completeData?.service,
  label: completeData?.label || completeData?.response?.label,
  reason: completeData?.reason || completeData?.response?.reason,
  assigned_to: completeData?.assigned_to,
  response_status: completeData?.response_status,
});
